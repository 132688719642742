<template>
    <div>
        <ts-page-title
            :title="$t('globalDriverFee.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('globalDriverFee.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <a-button
                        type="primary"
                        @click="addNew"
                        v-if="$can('create-global-driver-fees')"
                    >
                        {{ $t('addNew') }}
                    </a-button>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('globalDriverFee.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('globalDriverFee.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td class="tw-text-blue-400 tw-font-semibold">
                        {{ record.num_package_from }}
                    </td>
                    <td class="tw-text-red-400 tw-font-semibold">
                        {{ record.num_package_to }}
                    </td>
                    <td>
                        <ts-accounting-format
                            style="border: none"
                            :currency="record.express_vip_delivery_fee_type"
                            :digit="2"
                            :value="record.express_vip_delivery_fee"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            style="border: none"
                            :currency="record.express_normal_delivery_fee_type"
                            :digit="2"
                            :value="record.express_normal_delivery_fee"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            style="border: none"
                            :currency="record.standard_vip_delivery_fee_type"
                            :digit="2"
                            :value="record.standard_vip_delivery_fee"
                        />
                    </td>
                    <td>
                        <ts-accounting-format
                            style="border: none"
                            :currency="record.standard_normal_delivery_fee_type"
                            :digit="2"
                            :value="record.standard_normal_delivery_fee"
                        />
                    </td>
                    <td class="text-center tw-space-x-3">
                        <a
                            v-if="
                                $can('update-global-driver-fees') &&
                                !record.is_deleted
                            "
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                        <a-popconfirm
                            v-if="
                                $can('delete-global-driver-fees') &&
                                !record.is_deleted
                            "
                            placement="leftTop"
                            :title="$t('are_you_sure_to_delete')"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                            @confirm="deleteRecord(record)"
                        >
                            <a href="#" :disabled="record._deleting">
                                <i
                                    class="fas fa-circle-notch spin"
                                    v-if="record._deleting"
                                ></i>
                                <i class="fa fa-trash text-red" v-else></i>
                            </a>
                        </a-popconfirm>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('globalDriverFee.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
            width="450px"
        >
            <FormAction @cancel="clearEdit" />
        </a-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import FormAction from './form'
export default {
    name: 'globalDriverFee',
    components: {
        FormAction
    },
    data() {
        return {
            loading: false,
            showForm: false
        }
    },
    computed: {
        ...mapState('finance/globalDriverFee', ['resources', 'pagination']),
        search: {
            get() {
                return this.$store.state.finance.globalDriverFee.search
            },
            set(newValue) {
                this.$store.commit(
                    'finance/globalDriverFee/SET_SEARCH',
                    newValue
                )
                this.$store.commit('finance/globalDriverFee/RESET_CURRENT_PAGE')
            }
        },
        columns() {
            return [
                { name: this.$t('globalDriverFee.numPackageFrom') },
                { name: this.$t('globalDriverFee.numPackageTo') },
                { name: this.$t('globalDriverFee.expressVipDeliveryFee') },
                { name: this.$t('globalDriverFee.expressNormalDeliveryFee') },
                { name: this.$t('globalDriverFee.standardVipDeliveryFee') },
                { name: this.$t('globalDriverFee.standardNormalDeliveryFee') },
                {
                    name: this.$t('actions'),
                    style: 'text-align:center; width: 1px;'
                }
            ]
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true
            this.$store
                .dispatch('finance/globalDriverFee/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.$notify({ type: 'error', text: error.message })
                })
        },
        addNew() {
            this.$router.push({ name: 'global-driver-fee-create' })
        },
        edit(record) {
            this.$store.commit('finance/globalDriverFee/SET_EDIT_DATA', record)
            this.showForm = true
        },
        clearEdit() {
            this.$store.commit('finance/globalDriverFee/SET_EDIT_DATA', {})
            this.showForm = false
            this.fetchData()
        },
        deleteRecord(record) {
            record._deleting = true
            this.$store
                .dispatch('finance/globalDriverFee/destroy', record.id)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.fetchData()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    record._deleting = false
                })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('finance/globalDriverFee/RESET_STATE')
        next()
    }
}
</script>
